<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2025/febrero/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 17, febrero - marzo 2025</p>
          </header>
          <article>
            <h2>¿Qué nos une?</h2>
            <p class="author">Editorial / Gabriela Alfaro</p>
            <p>
              En esta edición de <em>COILaborando</em>, cuando celebramos San
              Valentín y la llegada de la primavera, reflexionemos sobre los
              valores que unen a estudiantes y a personal académico en una
              comunidad global a través de los cursos COIL. Valores como la
              empatía, la diversidad y el entendimiento mutuo son esenciales
              para construir un futuro inclusivo y lleno de oportunidades.
            </p>
            <p>
              La <strong>empatía</strong> nos permite comprender las emociones y
              las perspectivas de otros, creando un ambiente de apoyo durante
              los cursos. La <strong>diversidad</strong>, una riqueza
              invaluable, nos enseña a valorar las diferencias y a aprender de
              las perspectivas únicas que cada persona aporta. El
              <strong>entendimiento mutuo</strong>, resultado de la empatía y la
              diversidad, fomenta la cooperación y el respeto, haciendo que
              todas las personas se sientan valoradas.
            </p>
            <p>
              Estos pilares no sólo nos unen, sino que nos preparan para
              enfrentar los desafíos del futuro con una visión colaborativa e
              inclusiva. Construimos juntos y juntas un mundo donde cada voz
              cuenta y cada persona tiene la oportunidad de brillar.
            </p>
            <p class="my-6 pb-4">
              <img
                src="@/assets/images/coilaborando/2025/febrero/welcome.jpg"
                alt="Modelo ABPCL para el aprendizaje"
                class="mx-auto max-w-xs block"
              />
            </p>
            <p class="mt-3">
              ¡Les damos la bienvenida a esta nueva edición de
              <em>COILaborando</em>!
            </p>
          </article>
          <article>
            <h2>
              La Importancia Psicológica del Aprendizaje Colaborativo COIL
            </h2>
            <p class="author">Mederick Andrade</p>
            <p>
              Aprender es un proceso complejo en el que intervienen diversas
              variables y condiciones que facilitan o dificultan la construcción
              de nuevas estructuras. Podría decirse que
              <em>aprender es tejer una red de relaciones</em>. Al respecto, los
              Cursos de Aprendizaje Colaborativo Internacional en Línea (COIL)
              representan una alternativa transformadora que atiende las
              dimensiones psicológicas fundamentales del aprendizaje.
            </p>
            <p>
              Esta modalidad aprovecha la conectividad a internet para crear
              espacios de intercambio donde el desarrollo emocional y social
              ocupa un lugar central. Además, este modelo resignifica la
              percepción del entorno virtual, que deja de ser un espacio
              meramente recreativo o instrumental, para convertirse en un
              contexto genuino de crecimiento personal y profesional.
            </p>
            <p>
              De acuerdo con experiencias y casos de éxito, el modelo COIL
              fomenta el desarrollo de la inteligencia emocional ya que supone
              la interacción en un escenario culturalmente diverso. Este entorno
              psicopedagógico propicia que las y los estudiantes tomen un rol
              activo en su proceso de aprendizaje, lo que significa que pasan de
              ser espectadores a ser actores, no sólo de los procesos cognitivos
              sino de una experiencia integral que pone en perspectiva la
              resolución de problemas ante desafíos teóricos y prácticos.
            </p>
            <p>
              Las investigaciones en psicología educativa sugieren que
              estudiantes con mayor autoconocimiento y regulación emocional
              muestran mejor desempeño académico, pues factores como la
              motivación inciden en un mayor bienestar psicológico. Tales
              beneficios encuentran eco tanto de estudiantes como de docentes
              que han participado en la metodología COIL.
            </p>
            <p>
              De acuerdo con el campo de las neurociencias, la práctica
              constante en la resolución de problemas afina las conexiones
              neuronales asociadas con las habilidades sociales, esto significa
              que el cerebro aprende mejor en entornos donde prevalece la
              práctica del diálogo y la empatía. Todavía más: existe evidencia
              de que el aprendizaje significativo está íntimamente relacionado
              con el bienestar emocional. En ese sentido, el modelo COIL ofrece
              ventajas al exponer al alumnado a diversos estilos de pensamiento.
              Este intercambio constante de diferentes formas de pensar estimula
              la flexibilidad cognitiva, fomenta el pensamiento crítico y reduce
              los sesgos inherentes a las perspectivas culturalmente homogéneas.
            </p>
            <p>
              Podría decirse que el enfoque multidimensional del COIL refleja
              fielmente los entornos profesionales contemporáneos y con ello
              prepara psicológicamente al alumnado para los desafíos de un mundo
              laboral globalizado. Por ende, el método COIL no sólo prepara
              profesionales técnicamente competentes, sino que fomenta el
              desarrollo de individuos psicológicamente equilibrados,
              emocionalmente inteligentes y culturalmente sensibles, capaces de
              transformar y mejorar su entorno social.
            </p>
          </article>
          <article>
            <h2>
              Modelo ABPCL para el aprendizaje basado en proyectos colaborativos
              en línea, reseña
            </h2>
            <p class="author">Gabriela Alfaro y Alejandra Madrid</p>
            <p>
              Este artículo escrito por investigadores de la Universitat Oberta
              de Catalunya (UOC) presenta un estudio detallado sobre la
              aplicación y validación del modelo ABPCL (Aprendizaje Basado en
              Proyectos Colaborativos en Línea) en la asignatura "Competencias
              TIC". Este modelo combina el aprendizaje colaborativo con
              metodologías activas como el aprendizaje basado en proyectos y se
              enfoca en desarrollar habilidades clave para el trabajo en equipo
              en entornos virtuales.
            </p>
            <p>
              El estudio se basó en una metodología mixta, recopilando datos
              tanto cualitativos como cuantitativos a través de una encuesta
              aplicada a 978 estudiantes. Los resultados muestran que, en
              general, los estudiantes valoran positivamente el modelo,
              destacando aspectos como la organización del trabajo en equipo, la
              gestión de la información digital y la adquisición de habilidades
              blandas, como la empatía y la responsabilidad. Sin embargo,
              también se identificaron áreas de mejora, como la dificultad para
              manejar situaciones en las que algún miembro del equipo no cumple
              con sus responsabilidades. Además, se sugirió adaptar los roles
              dentro de los equipos a los perfiles profesionales de cada
              carrera, lo que podría hacer el modelo más relevante y efectivo.
            </p>
            <p class="my-6 pb-4">
              <img
                src="@/assets/images/coilaborando/2025/febrero/modeloABPCL.jpg"
                alt="Modelo ABPCL para el aprendizaje"
                class="mx-auto w-90 block"
              />
            </p>
            <p>
              Un hallazgo interesante fue que las mujeres tienden a valorar más
              positivamente el modelo que los hombres en casi todas las
              variables analizadas. Esto podría deberse a diferencias en cómo
              cada género percibe y se involucra en el trabajo colaborativo,
              aunque este aspecto merece una exploración más profunda en futuras
              investigaciones. También se observaron diferencias en la
              valoración del modelo entre las distintas carreras, lo que sugiere
              que el modelo podría ajustarse mejor a las necesidades específicas
              de cada área de estudio.
            </p>
            <p>
              En conclusión, el modelo ABPCL se presenta como una metodología
              efectiva para fomentar el aprendizaje colaborativo en línea, con
              un alto potencial de transferibilidad a otras asignaturas y
              contextos profesionales. Aunque hay aspectos que mejorar, como la
              gestión de roles y la comunicación asíncrona, los resultados son
              alentadores y abren nuevas líneas de investigación, especialmente
              en torno de las diferencias de género y entre disciplinas.
            </p>
            <p class="apa mt-10">
              Romero Carbonell, M.; Romeu Fontanillas, T.; Guitert Catasús, M.,
              y Baztán Quemada, P. (2024). "Validación del modelo ABPCL para el
              aprendizaje basado en proyectos colaborativos en línea".
              <em>RIED-Revista Iberoamericana de Educación a Distancia</em>,
              27(2).
              <a href="https://doi.org/10.5944/ried.27.2.39120" target="_blank"
                >https://doi.org/10.5944/ried.27.2.39120</a
              >
            </p>
          </article>
          <article>
            <h2>Eventos</h2>
            <h3>Feria de Colegios Comunitarios (Community Colleges) de EUA</h3>
            <p>
              Descubre cómo estudiar en un Community College en Estados Unidos
              puede ser el primer paso hacia tu carrera internacional.
            </p>
            <ul>
              <li>Entrada gratuita</li>
              <li>Información sobre programas, costos y becas</li>
            </ul>
            <p>
              Registro (reservación de lugar):
              <a
                href="http://bit.ly/CDMXedUSACCFair25"
                target="_blank"
                rel="noopener noreferrer"
                >bit.ly/CDMXedUSACCFair25</a
              >
            </p>
            <p class="my-6 pb-6">
              <a
                href="http://bit.ly/CDMXedUSACCFair25"
                target="_blank"
                rel="noopener noreferrer"
                ><img
                  src="@/assets/images/coilaborando/2025/febrero/evbuc.jpg"
                  alt="Feria de Colegios Comunitarios (Community Colleges) de EUA"
                  class="mx-auto max-w-lg block"
              /></a>
            </p>
            <h3>
              Convocatoria a la Conferencia Internacional de Intercambio Virtual
              IVEC 2025
            </h3>
            <p>
              ¡Los preparativos para la Conferencia Internacional de Intercambio
              Virtual (IVEC) 2025 están oficialmente en marcha! Conoce la
              convocatoria en el siguiente enlace y envía tu propuesta.
            </p>
            <p>
              🔗
              <a
                href="https://iveconference.org/proposals-2025/"
                target="_blank"
                rel="noopener noreferrer"
                >iveconference.org/proposals-2025</a
              >
            </p>
            <p>📅 Reserva la fecha: 15-17 de octubre de 2025</p>
            <p>
              Únete para interactuar con expertos globales en metodologías COIL,
              intercambio virtual e internacionalización sin salir de casa.
            </p>
            <p class="my-6">
              <a
                href="https://iveconference.org/proposals-2025/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/images/coilaborando/2025/febrero/IVEC-Heraklion.png"
                  alt="Modelo ABPCL para el aprendizaje"
                  class="mx-auto max-w-full block"
                />
              </a>
            </p>
          </article>
          <article>
            <h2>Testimonios</h2>
            <p>
              El Dr. Eduardo Espinosa Avila, técnico académico del Instituto de
              Física y profesor de la Facultad de Ingeniería realizó una primera
              experiencia COIL con el profesor Henry N. Roa, docente de la
              Facultad de Ingeniería de la Pontificia Universidad Católica de
              Ecuador, ambos impartiendo la asignatura “Inteligencia Artificial”
              de sus respectivos planes de estudios.
            </p>
            <p>
              La colaboración se realizó en un lapso de seis semanas durante las
              cuales los profesores pudieron aplicar la metodología de enseñanza
              basada en problemas, proponiendo un problema real a sus grupos:
              "Análisis de la mortalidad en Ecuador mediante
              <em>clustering</em>". Con esta metodología se estimula el
              pensamiento crítico y la gestión de emociones, entre otras
              habilidades útiles.
            </p>
            <p>
              Por su parte, el estudiantado de ambas facultades tuvo la
              oportunidad de conocer la metodología de resolución de problemas
              con aprendizaje automático (<em>machine learning</em>); desde el
              preprocesamiento de los datos, pasando por la elección del mejor
              modelo a aplicar hasta llegar a la evaluación de los resultados e
              incluso a la selección de valores de los hiperparámetros del
              modelo para mejorar los resultados finales.
            </p>
            <p>
              Además, la experiencia tuvo elementos interculturales adicionales:
              en México se participaron dos estudiantes de intercambio, uno de
              Colombia y otro de Perú, mientras que en Ecuador hubo un
              estudiante de ascendencia iraní; esto ayudó a enriquecer aún más
              esta colaboración en el sentido de la multiculturalidad.
            </p>
            <p>
              Los resultados de este primer curso COIL resultaron muy
              satisfactorios, se han publicado un par de artículos al respecto
              (ver referencias al final de este texto) y el Dr. Espinosa fue
              invitado a participar en una mesa de experiencias en la Segunda
              Jornada COIL UNAM 2025 en Mérida. Lo anterior ha permitido que se
              pueda plantear una segunda colaboración entre ambos profesores que
              está por iniciar en el semestre en curso (2025-2), esperamos que
              sea aún más gratificante dado el éxito de la experiencia del año
              pasado.
            </p>
            <p>
              Para el Dr. Espinosa este curso ha resultado un motor que lo
              impulsa a buscar nuevas formas de enseñanza y ya se está pensando
              en una colaboración con alguna escuela de habla inglesa para
              ayudar a sus estudiantes a mejorar también sus habilidades de
              comunicación en ese idioma.
            </p>
            <p>
              <em
                >Artículos surgidos del COIL sobre inteligencia artificial
                UNAM-PUCE</em
              >
            </p>
            <p class="apa mt-10">
              Espinosa Avila, Eduardo, y Alfaro Vega, Rocío Gabriela (junio de
              2024) "COIL: aprendizaje colaborativo internacional en línea, una
              experiencia internacional de bajo costo".
              <em>Boletín de la Academia Mexicana de Computación</em>, No. 2.
              <a
                href="https://amexcomp.mx/media/publicaciones/boletin_junio2024.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >https://amexcomp.mx/media/publicaciones/boletin_junio2024.pdf</a
              >.
            </p>
            <p class="apa">
              Espinosa Avila, Eduardo; Alfaro Vega, Rocío Gabriela, y Roa, Henry
              N. (24 de julio de 2024). "Trabajo 49. Experiencia de un curso de
              aprendizaje colaborativo internacional en línea en las Facultades
              de Ingeniería de la UNAM y la PUCE".
              <em>Encuentro Educatic 2024</em>. Programa de mesas.
              <a
                href="https://encuentro.educatic.unam.mx/educatic2024/programa-mesas.html"
                target="_blank"
                rel="noopener noreferrer"
                >https://encuentro.educatic.unam.mx/educatic2024/programa-mesas.html</a
              >. De próxima publicación
            </p>
          </article>
          <article>
            <h2>Para reflexionar…</h2>
            <p class="author">Gabriela Alfaro</p>
            <p>
              En la Facultad de Ingeniería estamos adoptado la modalidad de
              cursos COIL como una estrategia fundamental para proporcionar
              experiencias internacionales a nuestro alumnado y profesorado,
              promoviendo la vinculación con personas de distintas latitudes sin
              la necesidad de cruzar fronteras físicas. La oportunidad de
              trabajar en proyectos conjuntos con estudiantes y docentes de
              distintas culturas, formas de pensar y de obtener soluciones, les
              permitirá desarrollar competencias fundamentales como la
              comunicación intercultural, el trabajo en equipo y el uso efectivo
              de las tecnologías de comunicación de las que disponen.
            </p>
            <p>
              Dimos ya los primeros pasos con nuestros primeros cursos COIL,
              pero resulta importante avanzar a una fase de consolidación y
              expansión. Si bien hemos comenzado a dar pasos firmes en la
              difusión de la modalidad de cursos colaborativos internacionales
              entre nuestro profesorado y contamos con docentes sumamente
              interesado en esta modalidad, aún nos queda mucho trabajo por
              hacer. La impartición de talleres de preparación es un elemento
              fundamental que debemos continuar realizando de manera periódica,
              para guiar a nuestra comunidad en la estrategia práctica e
              incorporar la modalidad COIL dentro de la estructura académica de
              las asignaturas que imparten. Resulta fundamental consolidar una
              base sólida de profesorado motivado y capacitado para incorporarse
              a esta actividad de internacionalización.
            </p>
            <p>
              En un ejercicio de reflexión, podemos identificar uno de los
              principales desafíos a los que nos hemos enfrentado en este primer
              trayecto: ubicar pares académicos para concretar las propuestas
              internacionales de colaboración que se nos presentan cada vez con
              mayor frecuencia. Nuestro profesorado tiene aún dificultad para
              visualizar la forma en que un curso COIL podría encajar dentro de
              la planificación de su curso y cómo un proyecto colaborativo con
              otro docente y un grupo alterno de estudiantes se podría
              desarrollar en paralelo a la clase que ha venido impartiendo de
              manera cotidiana.
            </p>
            <p>
              En consecuencia, creemos que es fundamental integrar el concepto
              COIL de manera estructural en nuestros planes de estudio en todas
              las carreras que nuestra facultad imparte. Sin duda nos
              encontramos en un punto crucial de esta estrategia, considerando
              que nuestra entidad se encuentra inmersa en un proceso de
              actualización curricular, por lo que es importante contemplar
              dentro de los objetivos clave, la incorporación de la modalidad
              COIL como una herramienta para brindar un enfoque internacional a
              nuestros planes y programas de estudio; no como una estrategia
              aislada, sino formando parte integral de la planeación académica
              que permita acceder a experiencias internacionales de manera
              sistemática.
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "FebreroView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2025/febrero",
    };
  },
};
</script>
