<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/agosto/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
        </div>
        <section class="col w-full mt-10" id="newsletter">
          <header class="text-right">
            <p class="text-c_gold">Número 11, abril de 2024</p>
          </header>
          <article>
            <h2>Editorial</h2>
            <p class="mt-4 text-c_gold">
              <em>FIU COIL Virtual Exchange Leadership Institute</em>
            </p>
            <p>
              Cuatro integrantes de la Comisión COIL de la UNAM asistieron,
              entre febrero y abril, al curso COIL Virtual Exchange Leadership
              Institute (Instituto para el liderazgo en intercambio virtual
              COIL). Con duración de seis semanas, totalmente en línea, fue una
              gran oportunidad para reforzar conocimientos relacionados con la
              gestión de COIL en diferentes contextos universitarios e
              internacionales, así como para establecer nuevas relaciones con
              IES en el mundo interesadas en compartir, colaborar e interactuar
              con la UNAM.
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/abril/internet-FIU.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >Página en internet de FIU</span
              >
            </p>
            <p>
              A partir de lo aprendido, el equipo concluyó que es necesario
              generar apoyos que permitan definir, comunicar y aplicar:
            </p>
            <ol type="A">
              <li>
                La filosofía de COIL UNAM (como vía para promover equidad,
                accesibilidad e internacionalización).
              </li>
              <li>Estándares COIL UNAM.</li>
              <li>
                Un curso en línea autogestivo (MOOC) para formar en planeación,
                impartición y evaluación de COIL, que permita ofrecer un
                producto académico, homologar el discurso y facilitar a docentes
                UNAM y de otras IES nacionales y extranjeras el desarrollo de
                sus COIL.
              </li>
              <li>
                Una caja de herramientas (que incluya el
                <a
                  href="https://coil.unam.mx/#/recursos/tutorial"
                  target="_blank"
                  rel="noopener noreferrer"
                  >tutorial</a
                >
                y su cuaderno de trabajo, lecturas, el sitio web COIL UNAM y
                casos exitosos).
              </li>
              <li>
                La posibilidad de entregar un
                <em>Global Fluency Certificate</em> desde la CRAI, como paso
                hacia la certificación de los conocimientos en COIL.
              </li>
              <li>
                Procedimientos de definición operacional, recogida, análisis e
                interpretación de datos desde la planeación hasta el seguimiento
                de cursos COIL de la UNAM, para diseñar, construir y mantener un
                sistema de información institucional.
              </li>
              <li>
                Estrategias de posicionamiento de la UNAM en el ámbito COIL:
                <ol type="a">
                  <li>
                    al interior, a través del trabajo de quienes desde la
                    comisión COIL dan seguimiento a pares RURI de su área
                    disciplinaria, así como a diseñadores instruccionales y
                    pedagogos UNAM a través de acciones formativas, y
                  </li>
                  <li>
                    al exterior, al compartir recursos formativos y al
                    establecer vínculos y proyectos conjuntos.
                  </li>
                </ol>
              </li>
              <li>
                Políticas que visibilicen y valoren la participación en COIL
                (como su identificación en los historiales académicos de
                estudiantes y el reconocimiento público de docentes y
                funcionarios en el sitio web).
              </li>
            </ol>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/abril/presentacionCOIL.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >Lámina de una presentación en el COIL Leadership Institute, de
                la Universidad Estatal de Nueva York, donde surgió la
                metodología</span
              >
            </p>
            <p>
              En resumen, la participación en el FIU COIL Leadership Institute
              nos afirmó en la certeza de que COIL UNAM avanza por el camino
              correcto (pero también en la de todo lo que falta por hacer). Y
              además tuvimos la oportunidad de establecer nuevas relaciones de
              las que pronto habrá colaboraciones COIL, especialmente con Front
              Range Community College, una universidad comunitaria en Colorado,
              EUA, y con el Centro Paula Souza, un gran instituto tecnológico
              público del Estado de São Paulo, Brasil.
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/abril/quest-03.png"
                alt="Global citizenship education"
                class="mx-auto my-1"
              />
              <img
                src="@/assets/images/coilaborando/2024/abril/quest-07.png"
                alt="Global citizenship education"
                class="mx-auto my-1"
              />
              <img
                src="@/assets/images/coilaborando/2024/abril/quest-0809.png"
                alt="Global citizenship education"
                class="mx-auto my-1"
              />
            </p>
          </article>
          <article>
            <h2>Testimonio</h2>
            <p class="mt-4 text-c_gold">
              Asignaturas COIL en los planes y programas de estudio, Facultad de
              Ingeniería
            </p>
            <p>
              La Facultad de Ingeniería emprendió el proceso de modificación de
              los planes y programas de estudio de 14 de los 15 programas que en
              ella se imparten. En el proceso se señala la importancia de
              impartir asignaturas que permitan realizar trabajos colaborativos
              en el ámbito internacional, de modo que las asignaturas COIL se
              insertan como respuesta inmediata para atender esta necesidad. La
              facultad, además, considera las necesidades infraestructurales
              para esto y adopta medidas para resolverlas.
            </p>
            <p>
              En este marco, la DGECI ofreció la charla introductoria a las
              metodologías COIL que viene impartiendo en todas las entidades
              académicas de la UNAM, en dos sesiones virtuales el día 17 de
              abril, a las que asistieron en total 121 personas de todas las
              áreas académicas de la facultad. Nos acompañaron también Valerie
              Cardenas y Holly Schneider, representantes de la Universidad de
              Texas en Austin, con la que se plantean colaboraciones
              próximamente, y la mtra. María de la Paz Adelia Peña Clavel,
              coordinadora de la mediateca de la Escuela Nacional de Lenguas,
              Lingüística y Traducción (ENALLT), con la cual también tendremos
              actividades muy interesantes, de las que
              <strong>COILaborando</strong> informará próximamente.
            </p>
            <p>
              Entre los y las docentes de laF I que asistieron a las charlas de
              introducción a COIL, muchos se han interesado por adentrarse en la
              metodología, para lo cual la Comisión COIL se encuentra preparando
              un taller práctico, presencial, sobre adaptación de planes de
              estudios específicos de una materia para establecer módulos o
              cursos COIL. Este taller estará a cargo de la Dra. Guadalupe
              Vadillo, coordinadora de la Comisión COIL, y experimentada maestra
              <em>coiler</em>. A continuación se presentan resultados de la
              encuesta aplicada a los asistentes a las charlas en la FI.
            </p>
          </article>
          <article>
            <h2>Reseña</h2>
            <h3 class="text-right">
              Carmen King Ramírez, Jorge Francisco Sánchez-Jofras, José
              Rodríguez Gutiérrez y María Duarte “Un modelo virtual para la
              educación transfronteriza: colaboración académica entre México y
              Estados Unidos”. Frontera Norte 36
              <a
                href="https://doi.org/10.33679/rfn.v1i1.2362"
                target="_blank"
                rel="noopener noreferrer"
                >https://doi.org/10.33679/rfn.v1i1.2362</a
              >
            </h3>
            <p class="text-right">por Mariana Mújica Resendiz</p>
            <p>
              Este artículo presenta un análisis del impacto del Modelo Virtual
              de Educación Transfronteriza (MVET) en la formación de estudiantes
              universitarios. El estudio ofrece una visión inspiradora sobre
              cómo la diversidad cultural puede enriquecer el proceso educativo.
            </p>
            <p>
              A través de un enfoque cualitativo centrado en un estudio de caso
              detallado, el análisis profundiza en las experiencias de los
              participantes en un curso universitario que implementó el MVET.
              Los resultados revelan un logro notable: los estudiantes
              desarrollaron habilidades fundamentales para prosperar en entornos
              multiculturales. Este hallazgo resalta la efectividad del modelo,
              desafiando así las limitaciones tradicionales de la educación
              convencional.
            </p>
            <p>
              El artículo aborda también las barreras existentes para la
              participación en experiencias educativas internacionales, y
              destaca cómo el intercambio virtual está derribándolas y
              democratizando el acceso a la educación multicultural, en
              beneficio de estudiantes y profesores, en términos de participar
              en programas internacionales sin importar su ubicación geográfica.
            </p>
            <p>
              El texto destaca el papel crucial de la tecnología como
              facilitadora de la inclusión y la colaboración internacional,
              sentando las bases para una educación más equitativa y conectada.
              Este proyecto no sólo es una llamada a replantear nuestras
              concepciones sobre la educación, sino también una hoja de ruta
              para la internacionalización académica y la colaboración
              internacional. Es un testimonio elocuente de cómo la tecnología
              puede ser utilizada para derribar fronteras y construir puentes
              entre culturas, transformando así la educación en un motor de
              inclusión y entendimiento global.
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/abril/encabezado-KingRodriguez.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >Encabezado de la publicación</span
              >
            </p>
            <p class="my-4 lg:px-20 2xl:px-40">
              <img
                src="@/assets/images/coilaborando/2024/abril/graph-01.png"
                alt="Global citizenship education"
                class="mx-auto my-1"
              />
              <img
                src="@/assets/images/coilaborando/2024/abril/graph-02.png"
                alt="Global citizenship education"
                class="mx-auto"
              />
              <span class="text-center text-xs block mt-2"
                >Figuras incluidas en el artículo</span
              >
            </p>
          </article>
        </section>
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver otros números
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style scoped>
a.inline-block.bg-c_darkyellow {
  @apply my-4 rounded-sm;
  text-decoration: none !important;
}
ul li {
  @apply list-disc text-xs ml-6 my-2;
}
</style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";

export default {
  name: "AbrilView",
  components: {
    HeaderCoil,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2024/abril",
    };
  },
};
</script>
