<template>
  <section class="col w-full mt-10" id="newsletter">
    <header class="text-right">
      <p class="text-c_gold">Número 18, abril - mayo 2025</p>
    </header>
    <article>
      <h2>
        Construyendo puentes: de la naturaleza al teatro, del π a la equidad
      </h2>
      <p class="author">Editorial / Dagny Ludmila Valadez Valderrábano</p>
      <p>
        Marzo es un mes con una gran cantidad de efemérides reconocidas por
        varios países. Entre ellas se encuentran: el 1 de marzo, el
        <em>Día Internacional para la Cero Discriminación</em>; el 3, el
        <em>Día Mundial de la Naturaleza</em>; y uno de los más difundidos, el
        <em>Día Internacional de la Mujer</em>, el 8 de este mes. Otras
        conmemoraciones incluyen el <em>Día de π</em>, el
        <em>Día Internacional de la Felicidad</em>, el
        <em>Día de los Bosques</em>, el <em>Día del Agua</em>, el
        <em
          >Día de la Rememoración de las Víctimas de la Esclavitud y de la Trata
          Trasatlántica de Esclavos</em
        >, el
        <em
          >Día Internacional de Solidaridad con los Miembros del Personal
          Detenido o Desaparecido</em
        >, el <em>Día Mundial del Teatro y La Hora del Planeta</em>.
      </p>
      <p>
        Si bien cada una de estas fechas tiene un propósito independiente, es
        nuestro deber, como docentes y ciudadanos globales, comprender que todas
        estas conmemoraciones están interrelacionadas y encontrar las conexiones
        que nos permitan compartirlas en nuestras comunidades educativas.
      </p>
      <p>
        En el marco de los objetivos de sustentabilidad, igualdad y equidad,
        cero discriminación y erradicación de la pobreza, se intersectan
        elementos clave: los derechos de las mujeres, el cuidado del planeta
        para garantizar el acceso equitativo a los recursos naturales vitales y
        la protección de los derechos humanos. Para este punto, seguramente ya
        habrán identificado diversas conexiones, pero ¿qué ocurre con el
        <em>Día de π</em> y el <em>Día del Teatro</em>?
      </p>
      <p>
        Yo entiendo el primero como la representación de la investigación, la
        ciencia y la educación, herramientas fundamentales para desarrollar
        soluciones a los problemas comunes de todas las naciones. El
        <em>Día del Teatro</em>, por su parte, lo interpreto como una
        celebración de las artes y la capacidad creadora del ser humano, la cual
        nos permite desautomatizar la cotidianidad y apreciar la vida desde
        perspectivas diversas. Finalmente, la idea es construir comunidades
        felices, capaces de alcanzar una convivencia intercultural armónica
        entre sí y con su entorno.
      </p>
      <p>
        En este número, presentamos la reseña de un texto que nos lleva al
        terreno de la interseccionalidad entre el medio ambiente y la salud
        mental, además de mostrar cómo fomentar la interculturalidad a través de
        los cursos COIL. Incluimos un breve texto con las experiencias de
        alumnos y docentes de la Facultad de Ciencias Políticas y Sociales en un
        COIL que se llevó a cabo con éxito. Asimismo, añadimos una nota sobre
        las posibilidades de apoyo bibliográfico que ofrece la Facultad de
        Filosofía y Letras a profesores y alumnos interesados en participar en
        cursos COIL, junto con algunos eventos que esperamos sean de su interés.
      </p>
    </article>
    <article>
      <h2>Servicios en la biblioteca Samuel Ramos para COILers</h2>
      <p class="author">Erick Cañez Olvera</p>
      <p>
        La Biblioteca Samuel Ramos de la Facultad de Filosofía y Letras ofrece
        una amplia gama de servicios orientados a la academia. Entre ellos
        destacan el acceso a una colección bibliográfica especializada en
        humanidades y ciencias sociales, que incluye libros en línea, revistas
        académicas y recursos digitales (bases de datos). Además, proporciona
        asesorías en la búsqueda y gestión de información, orientación en el uso
        de bases de datos especializadas y capacitaciones sobre herramientas de
        investigación, citación y normativas bibliográficas, así como cursos de
        Desarrollo de Habilidades Informativas (DHI).
      </p>
      <p>
        Con el fin de apoyar a profesores y estudiantes que participen en cursos
        COIL, la biblioteca ofrece espacios diseñados ex profeso, con servicio
        de internet de alta velocidad para facilitar el trabajo
        académico-colaborativo. Asimismo, proporciona apoyo y orientación en el
        uso de diversas herramientas de inteligencia artificial para el
        tratamiento de la información en distintos ámbitos (traducción,
        análisis, redacción, etc.). También brinda asistencia en la gestión de
        información mediante herramientas digitales, todos estos servicios
        pueden ser proporcionados de manera presencial o en línea.
      </p>
    </article>
    <article>
      <h2>
        Vulnerabilidades de salud mental y justicia ambiental: una experiencia
        de aprendizaje internacional colaborativo en línea
      </h2>
      <p class="author">Adriana Constanza Neyra Zendejas</p>
      <p>
        En el mencionado artículo se explica la relación entre la justicia
        ambiental y la salud mental desde el punto de vista de la educación
        superior. Para ello, se emplea el modelo de Aprendizaje Internacional
        Colaborativo en Línea (COIL), con el propósito de vincular el trabajo de
        los estudiantes de la Universidad San Francisco de Quito y la
        Universidad del Sur de Alabama.
      </p>
      <p>
        El objetivo de esta colaboración es fortalecer la conciencia cultural y
        desarrollar competencias para abordar problemáticas globales
        relacionadas con la salud mental.
      </p>
      <p>
        Los aspectos centrales del artículo incluyen la exploración
        intercultural, la conciencia cultural, la metodología y evaluación, el
        impacto de la justicia ambiental, así como los resultados y desafíos.
        Por un lado, se resalta la importancia de la educación virtual
        intercultural para comprender los problemas de salud mental asociados
        con la justicia ambiental. Por otro lado, en cuanto a la metodología y
        evaluación, se fomenta la discusión de lecturas, la elaboración de
        pósteres y la participación en debates a través de plataformas en línea.
        Estos últimos evidenciaron una mejora considerable en la conciencia
        cultural de los estudiantes.
      </p>
      <p>
        Asimismo, el artículo aborda cómo la contaminación del aire, el cambio
        climático y otros fenómenos ambientales afectan la salud mental de las
        poblaciones, especialmente las más vulnerables. En cuanto a los
        resultados, se observó un aumento en el conocimiento y la
        sensibilización de los jóvenes sobre esta problemática. No obstante,
        también se señalaron dificultades tecnológicas y de participación que
        podrían representar desafíos para futuras ediciones del proyecto.
      </p>
      <p>
        En conclusión, el artículo busca integrar experiencias y aprendizaje
        colaborativo en línea con el fin de fomentar tanto el desarrollo
        profesional como personal de los estudiantes. Este esfuerzo por combinar
        la educación intercultural con la justicia ambiental promete una mayor
        comprensión de los desafíos que enfrenta la salud mental y ofrece un
        modelo replicable para el futuro de la educación.
      </p>
      <p>
        Finalmente, la aplicación de esta metodología en enfermería y otras
        áreas de la salud demuestra que el COIL es una herramienta pedagógica
        innovadora y sumamente valiosa.
      </p>
      <p class="apa mt-10">
        Newman SJ, Baker M, Hammonds LS (2025). Mental Health Vulnerabilities
        and Environmental Justice: A Collaborative Online International Learning
        Experience. <em>Creative Nursing</em>.
        <a
          href="https://doi.org/10.1177/10784535241307039"
          target="_blank"
          rel="noopener noreferrer"
          >10.1177/10784535241307039</a
        >
      </p>
    </article>
    <article>
      <h2>Eventos</h2>
      <h3>Global Virtual Exchange Summit 2025</h3>
      <p>
        La Cumbre Global de Intercambio Virtual ofrece una plataforma en la que
        se busca generar un espacio de reflexión y propiciar debates en torno a
        temas relacionados con el desarrollo de habilidades para la educación
        del siglo XXI.
      </p>
      <p class="my-6 pb-6">
        <a
          href="https://sce-vec.hkbu.edu.hk/Global-Virtual-Exchange-Summit-2025.html"
          target="_blank"
          rel="noopener noreferrer"
          ><img
            src="@/assets/images/coilaborando/2025/abril/gves-2025.png"
            alt="Global Virtual Exchange Summit 2025"
            class="mx-auto max-w-lg block"
        /></a>
      </p>
      <p>
        La cumbre se llevará a cabo el 8 y 9 de julio del 2025 en modalidad
        híbrida.
      </p>
      <p>
        Este evento no tendrá ningún costo y ofrece dos opciones de
        participación:
      </p>
      <ol type="1">
        <li>Presentación individual, con una duración de 15 minutos.</li>
        <li>
          Debate abierto y de discusión con ponentes, con una duración de 15 a
          20 minutos.
        </li>
      </ol>
      <p>
        Para participar, se debe enviar el trabajo de ponencia antes del 30 de
        mayo de 2025.
      </p>
      <h3>5º. Congreso de la red LATAM COIL</h3>
      <p>
        La red LATAM COIL, una de las más activas entre universidades
        hispanoamericanas, clave en los procesos de formación de pares
        colabortativos, ha lanzado la convocatoria para su quinto congreso, a
        celebrarse de manera virtual del 3 al 5 de septiembre de 2025.
      </p>
      <p>
        El tema central del congreso es “La inteligencia artificial como apoyo
        al coordinador en la sistematización de los proyectos COIL”.
      </p>
      <p>
        Como en ediciones anteriores, la convocatoria invita a personas
        coordinadoras de internacionalización, coordinadoras de programas COIL y
        docentes a participar en uno de los cuatro distintos formatos que se
        detallan en la siguiente infografía: sesiones inspiradoras, sesiones de
        investigación, paneles de discusión y talleres activos.
      </p>
      <p>
        La fecha límite para postular propuestas es el 30 de abril de 2025, y se
        pueden presentar en español o inglés.
      </p>
      <p>
        Más información en el
        <a
          href="https://www.linkedin.com/posts/red-latinoamericana-coil_5to-congreso-red-latam-coil-activity-7310684549133262849-2XJk?utm_source=social_share_send&utm_medium=member_desktop_web&rcm=ACoAAAY2t9EBMoBRcW3s3kQ7ByJvlEB7VW5aH6Y"
          target="_blank"
          rel="noopener noreferrer"
          >perfil de LinkedIn</a
        >
        de la red.
      </p>
    </article>
    <article>
      <h2>Testimonios</h2>
      <h3>COIL Participación comunitaria y movimientos sociales</h3>
      <p class="author">
        Marlen Yesenia Jiménez Martínez (UNAH)<br />Christian Octavio Diego
        Guerra (UNAM)
      </p>
      <p class="my-6 pb-6">
        <img
          src="@/assets/images/coilaborando/2025/abril/coil-grupo.png"
          alt="Global Virtual Exchange Summit 2025"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        El COIL desarrollado entre estudiantes de la asignatura Seminario II de
        la Licenciatura en Trabajo Social de la Universidad Nacional Autónoma de
        Honduras (UNAH) y la asignatura Participación Política y Movimientos
        Sociales de la Licenciatura en Ciencias Políticas y Administración
        Pública de la Universidad Nacional Autónoma de México (UNAM) tuvo como
        objetivo el intercambio de conocimiento, aprendizaje colectivo y
        reflexión, donde los estudiantes de la UNAM contribuyeron a través del
        análisis y propuestas metodológicas, desde el enfoque de políticas
        públicas sociales a los diagnósticos situacionales a nivel comunitario
        realizados por los estudiantes de la carrera de Trabajo Social de la
        UNAH, tomando como eje temático la participación comunitaria y los
        movimientos sociales.
      </p>
      <p>
        La organización del curso inició con una reunión entre los profesores de
        ambos cursos y las autoridades responsables de los COIL UNAM y UNAH a
        través de la Vicerrectoría de relaciones internacionales (VRI-UNAH). En
        este encuentro se establece acuerdo sobre los temas de las asignaturas
        que podían aportar al conocimiento y aprendizaje colaborativo de
        participantes. En una reunión posterior entre profesores, se detallaron
        los contenidos, la forma de trabajo y las fechas, a través de un
        cronograma compartido y socializado posterior con los equipos de trabajo
        conformados.
      </p>
      <p class="my-6 pb-6">
        <img
          src="@/assets/images/coilaborando/2025/abril/coil-unah.jpg"
          alt="Global Virtual Exchange Summit 2025"
          class="mx-auto max-w-lg block"
        />
      </p>
      <p>
        El curso inició el 3 de marzo de 2025 con la presentación de los
        estudiantes de ambas instituciones educativas, así como con la
        información necesaria para que los equipos de estudiantes iniciaran su
        colaboración. Posteriormente, tuvimos dos sesiones sincrónicas, una por
        semana, para conocer los avances de cada equipo. En estas sesiones, los
        alumnos narraban cómo se estaban coordinando y los acuerdos a los que
        llegaban para alcanzar el objetivo del curso. Al mismo tiempo, desde
        nuestras asignaturas apoyábamos a los estudiantes con sugerencias para
        sus trabajos.
      </p>
      <p>
        Concluimos el curso el 24 de marzo de 2025 con la exposición de cada
        equipo (4 equipos UNAM y 4 equipos UNAH), en la que presentaron las
        propuestas y acuerdos elaborados en conjunto para ampliar la observación
        de problemáticas o sugerir acciones desde la participación comunitaria y
        los movimientos sociales en relación con los diagnósticos situacionales
        ejecutados por los 4 equipos de trabajo de Trabajo Social-UNAH, Así
        mismo compartieron sus reflexiones sobre su experiencia en el curso.
      </p>
      <p>
        Dice Jenny Alejandra León Salgado, alumna de la UNAH: “La experiencia
        COIL con la UNAM facilitó el intercambio académico y cultural entre
        estudiantes y docentes. A través de actividades virtuales, se promovió
        el aprendizaje interdisciplinario, el trabajo en equipo y la reflexión
        crítica sobre diversas problemáticas sociales. Esta iniciativa
        fortaleció la formación profesional al conectar a los participantes con
        diferentes realidades y enfoques. Además, permitió desarrollar
        habilidades digitales y comunicativas en un entorno colaborativo. La
        interacción entre instituciones enriqueció la comprensión de los
        desafíos del trabajo social en distintos contextos”.
      </p>
      <p>
        Por otra parte, Adriana Bitia Ramos Segundo, alumna de la UNAM comenta:
        “Colaborar en el proyecto COIL me brindó la oportunidad de aproximarme a
        comprender otras realidades de América Latina desde un sentido social,
        económico y cultural. Mediante el trabajo de investigación colaborativo
        con el alumnado de la Universidad Autónoma de Honduras, fue posible
        hacer un estudio de caso con el cual pudimos poner en práctica los
        conocimientos teóricos vistos en clase. El desempeño en conjunto me
        introdujo a valorar la importancia de construir saberes de manera
        comunitaria a favor de un bien común”.
      </p>
      <p>
        Como responsables del curso consideramos que fue una experiencia
        enriquecedora y de mutua aprendizaje, donde los alumnos pusieron en
        práctica sus conocimientos para poder resolver problemas particulares
        del trabajo social desde la mirada de la participación política y los
        movimientos sociales. También, en términos académicos, logramos
        construir un puente de colaboración entre el Trabajo Social y la Ciencia
        Política, por lo que consideramos volver a establecer una colaboración
        internacional.
      </p>
      <p class="my-6 pb-6">
        <img
          src="@/assets/images/coilaborando/2025/abril/zoom-coil.png"
          alt="Global Virtual Exchange Summit 2025"
          class="mx-auto max-w-lg block"
        />
      </p>
    </article>
    <article>
      <h2>Para reflexionar…</h2>
      <p class="author">
        <em
          >"A river was here.<br />It was called... the river."<br />— A River
          Dies of Thirst, Najwan Darwish</em
        >
      </p>
      <p>
        En las líneas anteriores, el poeta palestino Najwan Darwish nos
        confronta con la desaparición de un río, sin nombre. Los puntos
        suspensivos nos permiten bautizar ese cuerpo de agua con el nombre que
        nos sea más familiar: el Papaloapan, el Usumacinta o el temido Río
        Bravo. Cualquiera de ellos representa los cuerpos de agua dulce de
        nuestro planeta.
      </p>
      <p>
        En los últimos años, hemos visto una creciente preocupación no solo por
        la mejor distribución del agua, sino también por la posibilidad de que
        lleguemos a carecer de ella. Buscar formas de vida más amigables con el
        planeta es una tarea colectiva que requiere comprender a la humanidad
        como una gran comunidad, unida por los mismos problemas, sin importar
        nacionalidad, raza, sexo, género o cualquier otra característica que
        pudiera diferenciarnos. Para alcanzar este tipo de convivencia, es
        indispensable desarrollar habilidades interculturales, que nos permitan
        valorar lo mejor de otras culturas y aprovecharlo para mejorar nuestra
        forma de vida.
      </p>
      <p>
        Las experiencias internacionales nos brindan la oportunidad de conocer
        mejor a los demás y, sobre todo, de reconocernos a nosotros mismos a
        través de la mirada externa. Esto nos ayuda a generar empatía y
        conciencia sobre nuestras acciones y nuestra relación con el entorno.
        Los cursos COIL son una herramienta fundamental para desarrollar
        dinámicas educativas transversalizadas por elementos interculturales,
        fomentando el conocimiento global desde diversas perspectivas y
        promoviendo soluciones creativas e innovadoras para problemas comunes,
        ahora denominados glocales.
      </p>
      <p>
        En el equipo COIL de la UNAM/DGECI, grupo encargado de difundir esta
        metodología, estamos convencidos de que esta forma de trabajo
        proporcionará los espacios necesarios para que nuestros estudiantes
        desarrollen habilidades esenciales para una convivencia armoniosa y
        sustentable, a través de la resolución colaborativa de problemas.
      </p>
      <p>Gracias por leernos y por compartir con nosotros este proyecto.</p>
      <p class="mt-4 text-right">Comisión COIL</p>
    </article>
  </section>
</template>

<script>
export default {
  name: "AbrilView",
  components: {},
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando/previos/2025/abril",
    };
  },
};
</script>
